import React from "react"
import Layout from "../components/layout";
import Seo from "../components/seo";
import {StaticImage} from "gatsby-plugin-image";

const UnderstandingYourBill = () => {
    const setAreaOver = (e) => {
        document.getElementById('myTooltip').style.left = (e.pageX - 200) + "px";
        document.getElementById('myTooltip').style.top = (e.pageY - 250) + "px";
        let tooltip = document.getElementById('myTooltip');
        tooltip.style.display = 'block';
        tooltip.innerHTML = e.target.getAttribute('data-title');
    }

    const setAreaOverHide = (e) => {
        let tooltip = document.getElementById('myTooltip');
        tooltip.style.display = 'none';
        tooltip.innerHTML = '';
    }

    return (
        <Layout>
            <Seo title="Understanding Your Bill" description="SEIEC UNDERSTANDING YOUR BILLS"/>
            <div className="w-full px-8">
                <h2 className="item-title">UNDERSTANDING YOUR BILLS</h2>
                <div style={{position: "relative"}}>
                    <StaticImage
                        src="../images/15037_insert_proof_08_04_2023_updated.png"
                        alt="bill image"
                        placeholder="blurred"
                        layout="constrained"
                        useMap="#planetmap"
                    />
                </div>
            </div>
        </Layout>
    )
}

export default UnderstandingYourBill